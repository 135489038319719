<template>
        <div class=""  @click="gotopage" >
             <div class="avatar mb-1">
                <div  class="avatar-content">
                    <img :src="page.icon" alt="" width="40">
                </div>
            </div>
            <!-- <h2 class="fw-bolder">{{ page.status }}</h2> -->
            <p class="card-text">{{ page.name.slice(0,28) }}{{page.name.length>28 ? '...':''}}</p>
        </div>
           
    
</template>

<script>
export default {
    name: 'PageCard',

    props: ['page'],
    
    computed: {
        companyId () {
            return this.$route.params.companyId
        },
        moduleId () {
            return this.$route.params.moduleId
        },
        menuId () {
            return this.$route.params.menuId
        },
        pageId () {
            return this.page.id
        },
        basicParams () {
            return {
                companyId: this.companyId,
                moduleId: this.moduleId,
                menuId: this.menuId,
                pageId: this.pageId,
            }
        }
    },
    methods: {
        gotopage() {
            
            /**
             * if need any condition for the route
             */
            // if(this.page.route == 'accounts-list') {
            //     this.$router.push({name: this.page.route , 
            //         params: {
            //             ...this.basicParams,
            //             accountType: 'asset'
            //         }
            //     })
            // }

            

            this.$router.push({name: this.page.route ,
                params: {
                    ...this.basicParams,
                    accountType: 'asset'
                },
                query: {
                    start: this.getFirstDate(),
                    end: this.getLastDate(),
                    page: 1
                }
            })

        },

        getFirstDate () {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 2).toISOString().split('T')[0];
        },
        getLastDate () {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];
        }
    }


}
</script>

<style scoped>
    .avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #E7F3FF;
        /* background-color: #F0F2F5; */
        border: 2px solid #a2caf1;
    } 
    .avatar:hover {
        border: 4px solid #6baef1;
    }
    .avatar-content{
        margin: auto;
    }

    
</style>
