import {includes, replace} from 'lodash'
import Network from '@/services/network'
import {ref} from 'vue'
import useDate from '@/services/utils/day'
import figureFormatter from '@/services/utils/figureFormatter.js'

const {
    firstDateOfQuarter,
    lastDateOfQuarter,
    lastDateOfYear,
    firstDateOfYear,
    firstDateOfLastYear,
    lastDateOfLastYear,
    firstDateOfMonth,
    lastDateOfMonth,
    firstDateOfLastMonth,
    lastDateOfLastMonth
} = useDate()

const network = new Network

export function useCardStatistics(params) {
    const statisticsItem = ref({
        income: {
            key: 'income',
            name: 'Total Income',
            route: 'total-income',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        expense: {
            key: 'expense',
            name: 'Total Expenses',
            route: 'total-expense',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'expense'
        },
        profit_and_loss: {
            key: 'profit_and_loss',
            name: 'Total Income over expense',
            route: 'total-ioe',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'profit_and_loss'
        },
        receipt: {
            key: 'receipt',
            name: 'Total Receipts',
            route: 'total-receipts',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        payment: {
            key: 'payment',
            name: 'Total Payment',
            route: 'total-payment',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        cash_and_bank: {
            key: 'cash_and_bank',
            name: 'Total Cash & Bank Balance',
            route: 'total-cash-bank-balance',
            value: 0,
            url: 'cash-bank-register-dashboard',
            image: 'cash_and_bank'
        },
        receivable: {
            key: 'receivable',
            name: 'Total Receivables',
            route: 'total-receivables',
            value: 0,
            url: 'receivable-register-dashboard',
            image: 'receivable'
        },
        payable: {
            key: 'payable',
            name: 'Total Payables',
            route: 'total-payables',
            value: 0,
            url: 'payable-register-dashboard',
            image: 'payable'
        }
    })
    const {commaFormat} = figureFormatter()

    const makeStatistics = (statisticsData, notIncludeArr) => {
        let statistics = []
        Object.keys(statisticsData).forEach((key) => {
            if (!includes(notIncludeArr, key)) {
                statistics.push(
                    {
                        name: replace(key, /[\_]/g, ' '),
                        value: commaFormat(statisticsData[key]),
                        key: key
                    }
                )
            }
        })
        return statistics
    }

    function setValue(payload, key) {
        const {
            cash_and_bank,
            expense,
            income,
            payable,
            receivable,
            profit_and_loss,
            receipt,
            payment
        } = payload

        if (key.length) {
            statisticsItem.value[key].value = payload[key]
            return false;
        }

        statisticsItem.value.income.value = income
        statisticsItem.value.cash_and_bank.value = cash_and_bank
        statisticsItem.value.expense.value = expense
        statisticsItem.value.payable.value = payable
        statisticsItem.value.receivable.value = receivable
        statisticsItem.value.profit_and_loss.value = profit_and_loss
        statisticsItem.value.receipt.value = receipt
        statisticsItem.value.payment.value = payment
    }

    function getDashboardData(query = '', key = '') {
        network.api('get', `/get/dashboard?company_id=${params.companyId}&module_id=${params.moduleId}` + query)
            .then(res => {
                if (res.status) {
                    setValue(res.data, key)
                }
            })
    }

    //`"start":"","end":""`
    const timeRange = [
        {
            name: 'This Month',
            value: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `
        },
        {
            name: 'Last Month',
            value: `
            "start":"${firstDateOfLastMonth}",
            "end":"${lastDateOfLastMonth}"
            `
        },
        {
            name: 'This Quarter',
            value: `
            "start":"${firstDateOfQuarter}",
            "end":"${lastDateOfQuarter}"
            `
        },
        {
            name: 'This Year',
            value: `
            "start":"${firstDateOfYear}",
            "end":"${lastDateOfYear}"
            `
        },
        {
            name: 'Last Year',
            value: `
            "start":"${firstDateOfLastYear}",
            "end":"${lastDateOfLastYear}"
            `
        }

    ]

    return {
        makeStatistics,
        timeRange,
        getDashboardData,
        statisticsItem,
    }
}
