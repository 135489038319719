<template>
        <div class=""  @click="gotopage" >
             <div class="avatar mb-1">
                <div  class="avatar-content">
                    <img :src="page.icon" alt="" width="40">
                </div>
            </div>
            <!-- <h2 class="fw-bolder">{{ page.status }}</h2> -->
            <p class="card-text">{{ page.name.slice(0,28) }}{{page.name.length>28 ? '...':''}}</p>
        </div>


</template>

<script>
export default {
    name: 'PageCard',

    props: ['page'],

    computed: {
        companyId () {
            return this.$route.params.companyId
        },
        moduleId () {
            return this.$route.params.moduleId
        },
        menuId () {
            return this.$route.params.menuId
        },
        pageId () {
            return this.page.id
        },
        basicParams () {
            return {
                companyId: this.companyId,
                moduleId: this.moduleId,
                menuId: this.menuId,
                pageId: this.pageId,
            }
        }
    },
    methods: {
        gotopage() {
            if(this.page.route == 'accounts-list') {
                this.$router.push({name: this.page.route ,
                    params: {
                        ...this.basicParams,
                        accountType: 'asset'
                    }
                })
            }

            if(this.page.route == 'business-location') {
              this.$router.push({name: this.page.route ,
                params: {
                  ...this.basicParams,
                  accountType: 'fg_warehouse'
                }
              })
            }

            if(
                this.page.route == 'specification-list'
                ) {
                this.$router.push({name: this.page.route ,
                    params: {
                        ...this.basicParams
                    }
                })
            }

            if(this.page.route == 'business-list') {
                this.$router.push({name: this.page.route ,
                    params: {
                        ...this.basicParams
                    }
                })
            }

            if(this.page.route == 'inventory-list') {
                this.$router.push({name: this.page.route ,
                    params: {
                        ...this.basicParams,
                        accountType: 'finished_goods'
                    }
                })
            }

            if(this.page.route == 'day-book') {
                this.$router.push({name: this.page.route ,
                    params: {
                        companyId: this.companyId,
                        moduleId: this.moduleId,
                        menuId: this.menuId,
                        pageId: this.pageId
                    },
                    query: {
                        start: new Date().toISOString().split('T')[0],
                        end: new Date().toISOString().split('T')[0],
                        page: 1
                    }
                })
            }

            if(this.page.route == 'general-ledger'
                || this.page.route == 'trial-balance'
                || this.page.route == 'balance-sheet'
                || this.page.route == 'profit-loss'
                || this.page.route == 'journal-list'
                || this.page.route == 'payment-voucher-list'
                || this.page.route == 'contra-voucher-list'
                || this.page.route == 'receipt-voucher-list'
                || this.page.route == 'purchase-list'
                || this.page.route == 'purchase-return-list'
                || this.page.route == 'purchase-return-add'
                || this.page.route == 'sales-return-list'
                || this.page.route == 'sales-return-add'
                || this.page.route == 'pay-bill-list'
                || this.page.route == 'money-receipt-list'
                || this.page.route == 'sales-list'
                || this.page.route == 'purchase-bill-add'
                || this.page.route == 'purchase-bill-pay'
                || this.page.route == 'sales-invoice-add'
                || this.page.route == 'sales-invoice-receipt'
                || this.page.route == 'payable-register'
                || this.page.route == 'receivable-register'
                || this.page.route == 'cash-bank-register'
                || this.page.route == 'stock-register'
                || this.page.route == 'stock-ledger'
                || this.page.route == 'export-datasheet'
                || this.page.route == 'bank-reconciliation'
                || this.page.route == 'stock-transfer-list'
                || this.page.route == 'stock-adjustment-list'
                || this.page.route == 'party-ledger'
            ) {
                this.$router.push({name: this.page.route ,
                    params: {
                        ...this.basicParams
                    },
                    query: {
                        start: this.getFirstDate(),
                        end: this.getLastDate(),
                        page: 1
                    }
                })
            }

          if(
              this.page.route == 'contacts-all' ||
              this.page.route == 'contacts-employee' ||
              this.page.route == 'contacts-supplier' ||
              this.page.route == 'contacts-customer' ||
              this.page.route == 'contacts-member'
          ) {
            this.$router.push({name: this.page.route ,
              params: {
                companyId: this.companyId,
                moduleId: this.moduleId,
                menuId: this.menuId,
                pageId: this.pageId
              }
            })
          }

          if(this.page.route == 'contacts-create') {
            this.$router.push({name: this.page.route ,
              params: {
                companyId: this.companyId,
                moduleId: this.moduleId,
                menuId: this.menuId,
                pageId: this.pageId
              }
            })
          }

          if (this.page.route == 'contact-register') {
            this.$router.push({
              name: this.page.route,
              params: {
                companyId: this.companyId,
                moduleId: this.moduleId,
                menuId: this.menuId,
                pageId: this.pageId
              },
              query: {
                page: 1
              }
            })
          }

            if(this.page.route === 'company-settings') {
                this.$router.push({name: this.page.route ,
                    params: {
                        companyId: this.companyId,
                        moduleId: this.moduleId,
                    }
                })
            }

        },

        getFirstDate () {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 2).toISOString().split('T')[0];
        },
        getLastDate () {
            const date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().split('T')[0];
        }
    }


}
</script>

<style scoped>
    .avatar{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #E7F3FF;
        /* background-color: #F0F2F5; */
        border: 2px solid #a2caf1;
    }
    .avatar:hover {
        border: 4px solid #6baef1;
    }
    .avatar-content{
        margin: auto;
    }


</style>
